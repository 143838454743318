<template>
  <b-card>
    <title-text />
    <sub-title-text />
    <content-text />
    <button-text />
    <status-select />
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import TitleText from '@/views/Admin/Mod_leads/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Mod_leads/elements/SubTitleText.vue'
import ContentText from '@/views/Admin/Mod_leads/elements/ContentText.vue'
import ButtonText from '@/views/Admin/Mod_leads/elements/ButtonText.vue'
import StatusSelect from '@/views/Admin/Mod_leads/elements/StatusSelect.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    SubTitleText,
    ContentText,
    ButtonText,
    StatusSelect,
  },
}
</script>
